import React from 'react';
import {
  FooterContainer,
  FooterContent,
  RelevantLinks,
  SocialWrapper,
  MapWrapper,
  LogoWrapper,
} from './styles';
import MapSVG from '../../../styles/svgs/map.svg';
import Logo from '../../../styles/svgs/white_logo.svg';
import Link from 'next/link';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Menu } from '../../../types/schema';

export type Item = {
  label: string;
  url: string;
};

export type MenuType = {
  title?: string;
  locale: string;
  items: Item[];
};

export type FooterTemplateProps = {
  data: {
    technologies: Menu[];
    footerPt: Menu[];
    footerEn: Menu[];
    services: Menu[];
  };
  locale: string;
};

const FooterTemplate: React.FC<FooterTemplateProps> = ({ data, locale }) => {
  const isEn = locale === 'en';

  const menu = isEn ? (data.footerEn[0] as MenuType) : (data.footerPt[0] as MenuType);
  const services = data.services[0] as MenuType;
  const technologies = data.technologies[0] as MenuType;

  const allMenus = isEn ? data.footerEn : data.footerPt;
  const takeAction = allMenus.find((menu) => menu.title?.includes('take action'));

  return (
    <FooterContainer>
      <FooterContent>
        <div className="container-items">
          <LogoWrapper>
            <Link
              href={`/${locale || ''}`}
              className="logo"
              title={isEn ? 'Go to home page' : 'Ir para a página inicial'}
            >
              <Logo />
              <span hidden>App Masters</span>
            </Link>
          </LogoWrapper>

          <h1>{isEn ? 'Follow us!' : 'Siga-nos!'}</h1>
          <SocialWrapper>
            <a
              href="https://www.instagram.com/appmasters.io/"
              target="_blank"
              rel="noopener noreferrer external nofollow"
              title="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/appmasters.io/"
              target="_blank"
              rel="noopener noreferrer external nofollow"
              title="LinkedIn"
            >
              <FaLinkedin />
            </a>
          </SocialWrapper>
        </div>
        {isEn && (
          <div className="container-items">
            <h1>{services?.title}</h1>
            <RelevantLinks>
              {services &&
                services.items?.map((service) => (
                  <Link key={service.label} href={service.url}>
                    {service.label}
                  </Link>
                ))}
            </RelevantLinks>

            <h1>{technologies?.title}</h1>
            <RelevantLinks>
              {technologies?.items?.map((technology) => (
                <Link key={technology.label} href={technology.url}>
                  {technology.label}
                </Link>
              ))}
            </RelevantLinks>
          </div>
        )}
        <div>
          <h1>{isEn ? menu?.title : 'Links relevantes'}</h1>
          <RelevantLinks>
            {menu &&
              menu.items?.map((item) => (
                <Link href={item.url} key={item.label}>
                  {item.label}
                </Link>
              ))}
          </RelevantLinks>
        </div>
        <div>
          {takeAction && takeAction.items && takeAction.items.length > 0 && (
            <>
              <h1>{isEn ? 'Take action' : 'Agir agora'}</h1>
              <RelevantLinks>
                {takeAction.items.map((item) => (
                  <Link href={item?.url as string} key={item?.label}>
                    {item?.label}
                  </Link>
                ))}
              </RelevantLinks>
            </>
          )}
        </div>
        <div>
          <h1>{isEn ? 'Address' : 'Endereço'}</h1>
          {isEn ? (
            <p>
              Rio Branco Avenue 3480
              <br />
              Room 501
              <br />
              Juiz de Fora - MG - Brazil
              <br />
              ZIP Code 36025-020
            </p>
          ) : (
            <p>
              Av. Barão do Rio Branco 3480
              <br />
              Sala 501
              <br />
              Bairro Alto dos Passos, Juiz de Fora - MG
              <br />
              CEP 36025-020
            </p>
          )}
          <MapWrapper>
            <br />
            <a
              href="https://www.google.com.br/maps/place/App+Masters/@-21.7725999,-43.3486377,17z/data=!3m1!4b1!4m5!3m4!1s0x989b5d3104ae57:0x9216e0df6326e89f!8m2!3d-21.7725999!4d-43.3471735"
              target="_blank"
              rel="noopener noreferrer external nofollow"
              title={isEn ? 'Our location on Google maps' : 'Nossa localização no Google maps'}
            >
              <MapSVG />
            </a>
          </MapWrapper>
        </div>
      </FooterContent>
    </FooterContainer>
  );
};

export default FooterTemplate;
