import React from 'react';
import { useRouter } from 'next/router';
import Footer from '../../components/elements/Footer';
import Header from '../../components/elements/Header';
import Newsletter from '../../components/elements/Newsletter';
import { Menu } from '../../types/schema';
import { PageLayoutWrapper } from './styles';

export type MenuProps = {
  header: Menu[];
  technologies: Menu[];
  services: Menu[];
  footerPt: Menu[];
  footerEn: Menu[];
};

type PageLayoutProps = {
  menus: MenuProps;
  locale: string;
  isHeaderTransparent?: boolean;
  hideMenu?: boolean;
};

const PageLayout: React.FC<React.PropsWithChildren<PageLayoutProps>> = ({
  menus,
  children,
  locale,
  isHeaderTransparent = false,
  hideMenu,
}) => {
  const { header, ...footerData } = menus || {
    header: [],
    technologies: [],
    footerPt: [],
    footerEn: [],
    services: [],
  };

  const router = useRouter();

  return (
    <PageLayoutWrapper key={`${locale}_${router.asPath}`}>
      <Header
        hideMenu={hideMenu}
        locale={locale}
        data={{ menus: header }}
        isTransparent={isHeaderTransparent}
      />
      <main key={locale}>{children}</main>
      <Newsletter locale={locale} />
      <Footer locale={locale} data={footerData} />
    </PageLayoutWrapper>
  );
};

export default PageLayout;
