import React, { FC, useMemo, useState } from 'react';
import { NewsletterContainer } from './styles';
import { FiMail } from 'react-icons/fi';
import { Input } from '../../ui/Input';
import logging from '../../../utils/logging';
import { useRouter } from 'next/router';
import { submitHubSpotForm } from '../../../utils/hubspot';
import * as gtag from '../../../utils/gtag';
import { GoInfo } from 'react-icons/go';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { checkIsHuman, verifyRecaptchaToken } from '../../../utils/recaptcha';
import Button from '../../ui/Button';
import { LoadingIcon } from '../../ui/LoadingIcon';
import { checkValidEmail } from '../../../utils/zeroBounce';
import ClientComponent from '../../ui/ClientComponent/ClientComponent';

const Newsletter: FC<{ locale: string }> = ({ locale }) => {
  const router = useRouter();

  const [email, setEmail] = React.useState('');
  const [checked, setChecked] = React.useState(false);
  const [newsChecked, setNewsChecked] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<'check' | 'email' | 'captcha' | 'other'>();
  const [success, setSuccess] = useState(false);
  const [validEmail, setValidEmail] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const showForm = useMemo(() => {
    if (typeof window === 'undefined') return true;
    if (success) return true;

    const today = new Date();
    const lastDateString = localStorage.getItem('NEWSLETTER_LAST_SUBSCRIPTION');

    if (!lastDateString) return true;

    const successDate = new Date(lastDateString);
    return (
      today.getDate() !== successDate.getDate() ||
      today.getMonth() !== successDate.getMonth() ||
      today.getFullYear() !== successDate.getFullYear()
    );
  }, [success]);

  const handleChangeEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(e.target.value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  if (!showForm) return <></>;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError(undefined);
    setLoading(true);

    const { validEmail, response } = await checkValidEmail(email);

    if (!validEmail) {
      logging.info('Attempted to subscribe in the newsletter without a valid email', {
        email,
        response,
      });
      setError('email');
      setValidEmail(false);
      return setLoading(false);
    }

    if (!checked) {
      logging.info('Attempted to subscribe in the newsletter without check terms and conditions', {
        email,
      });
      setError('check');
      return setLoading(false);
    }

    if (executeRecaptcha) {
      const token = await executeRecaptcha('newsletter_subscribe');
      const verify = await verifyRecaptchaToken(token);

      // Checking if robot
      if (!verify.success) {
        logging.info('[newsletter_subscribe] Recaptcha failed', { email, verify });
        setError('captcha');
        return setLoading(false);
      } else if (!checkIsHuman(verify)) {
        logging.info('[newsletter_subscribe] User with low recaptcha score', { email, verify });
        setError('captcha');
        return setLoading(false);
      }
    }

    gtag.event({ action: 'newsletter_subscribe' });

    try {
      const title = document.title?.split(' - ')?.[0] || document.title || '';
      const utmSource = router.query?.utm_source as string;
      const utmMedium = router.query?.utm_medium as string;
      const utmCampaign = router.query?.utm_campaign as string;

      const { status } = await submitHubSpotForm(
        process.env.NEXT_PUBLIC_HUBSPOT_NEWSLETTER_FORM_ID || '',
        process.env.NEXT_PUBLIC_HUBSPOT_NEWSLETTER_PORTAL_ID || '',
        {
          email,
          ['hs_language']: navigator.language.toLowerCase() || router.locale || 'pt-br',
          ...(utmSource && { ['utm_source']: utmSource }),
          ...(utmMedium && { ['utm_medium']: utmMedium }),
          ...(utmCampaign && { ['utm_campaign']: utmCampaign }),
        },
        {
          pageName: title,
          pageUri: window.location.href,
        },
        newsChecked,
      );

      if (status !== 200) throw Error('Error on post to Hubspot');

      const { default: ReactPixel } = await import('react-facebook-pixel');
      ReactPixel.track('Lead');

      logging.info('Newsletter subscription has been submitted successfully', { email });

      setLoading(false);
      setSuccess(true);
      localStorage.setItem('NEWSLETTER_LAST_SUBSCRIPTION', new Date().toString());
    } catch (error) {
      logging.error('Error on newsletter subscription', {
        error,
        errorMessage: (error as { message: string }).message,
        data: { email },
      });
      setError('other');
      setLoading(false);
      setSuccess(false);
    }
  };

  const isEn = locale === 'en';

  return (
    <NewsletterContainer>
      <div className="content">
        <div className="text-wrapper">
          <h1>
            <FiMail />
            {success
              ? isEn
                ? 'Your subscription has been submitted'
                : 'Sua inscrição foi enviada'
              : isEn
                ? 'Subscribe to our newsletter'
                : 'Inscreva-se na nossa newsletter'}
          </h1>
          <p>
            {success
              ? isEn
                ? 'Now you will receive updates about our latest news and products. Thank you! '
                : 'Você receberá atualizações sobre novidades e produtos mais recentes via e-mail.'
              : isEn
                ? 'Stay up to date with our latest news and products'
                : 'Fique por dentro das nossas novidades e produtos mais recentes'}
          </p>
        </div>

        {!success && (
          <form onSubmit={handleSubmit}>
            {error === 'email' && !validEmail && (
              <p className="error" role="alert" aria-atomic>
                <GoInfo />
                {isEn ? 'Please provide a valid email' : 'Por favor, insira um email válido'}
              </p>
            )}
            {error === 'captcha' && validEmail && (
              <p className="error" role="alert" aria-atomic>
                <GoInfo />
                {isEn
                  ? `Sorry, but we identified you as a robot. If we're wrong, please contact us`
                  : 'Desculpe, mas você foi identificado como um robô. Se não for o caso, entre em contato conosco'}
              </p>
            )}
            {error === 'check' && !checked && validEmail && (
              <p className="error" role="alert" aria-atomic>
                <GoInfo />
                {isEn
                  ? 'You must accept our terms and conditions'
                  : 'Você precisa aceitar os termos e condições'}
              </p>
            )}
            <div className="input-wrapper">
              <label className="sr-only" htmlFor="newsletter-email">
                Email
              </label>
              <Input
                id="newsletter-email"
                value={email}
                onChange={handleChangeEmail}
                placeholder={'Email'}
                type="email"
              />
              <Button type="submit" disabled={loading}>
                {loading && <LoadingIcon />}
                {isEn ? 'Subscribe' : 'Inscrever-se'}
              </Button>
            </div>
            {validEmail && (
              <>
                <div className="checkbox-wrapper" aria-live="assertive">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={newsChecked}
                      onChange={(e) => setNewsChecked(e.target.checked)}
                    />{' '}
                    <span className="checkmark" />
                    {isEn ? 'Keep me up to date' : 'Mantenha-me informado de novidades'}
                  </label>
                </div>
                <div
                  className={`checkbox-wrapper ${!checked ? 'shake-animation' : ''}`}
                  aria-live="assertive"
                >
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                    />{' '}
                    <span className="checkmark" />
                    {isEn
                      ? 'By ticking here, you are confirming that you have read and agreed with the'
                      : 'Confirmo que li e aceito os'}
                  </label>
                  {isEn ? (
                    <a
                      href="https://www.appmasters.io/en/terms-and-conditions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms and conditions
                    </a>
                  ) : (
                    <a href="https://www.appmasters.io/pt/termos-e-condicoes">termos e condições</a>
                  )}
                </div>
              </>
            )}
          </form>
        )}
      </div>
    </NewsletterContainer>
  );
};

export default function NewsletterClient({ locale }: { locale: string }) {
  return (
    <ClientComponent>
      <Newsletter locale={locale} />
    </ClientComponent>
  );
}
