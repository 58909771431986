import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import {
  Dropdown,
  DropdownItem,
  Hamburger,
  HamburgerMenu,
  Header,
  Nav,
  NavItem,
  NavMenu,
  SearchInput,
  SearchBarWrapper,
  SearchBarIcon,
  FormWrapper,
  LanguageDisplay,
  WorldIconHolder,
  DropdownArrow,
} from './styles';
import USFlag from '../../../styles/svgs/english.svg';
import BRFlag from '../../../styles/svgs/portuguese.svg';
import WorldIcon from '../../../styles/svgs/world.svg';
import ArrowIcon from '../../../styles/svgs/arrow-left.svg';
import Logo from '../../../styles/svgs/white_logo.svg';
import { FaSearch } from 'react-icons/fa';
import Button from '../../ui/Button';
import { Menu } from '../../../types/schema';

export type Item = {
  label: string;
  url: string;
};

export type MenuType = {
  title: string;
  locale: string;
  direction?: 'horizontal' | 'vertical';
  items: Item[];
};

export type HeaderTemplateProps = {
  data: {
    menus: Menu[];
  };
  locale: string;
  isTransparent: boolean;
  hideMenu?: boolean;
};

const HeaderTemplate: React.FC<HeaderTemplateProps> = ({
  hideMenu,
  data,
  locale,
  isTransparent,
}) => {
  const [open, setOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [scroll, setScroll] = useState(0);
  const isEn = locale === 'en';
  const menu = isEn ? (data.menus[1] as MenuType) : (data.menus[0] as MenuType);

  useEffect(() => {
    const handler = () => {
      setScroll(window.scrollY);
    };
    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);

  const SearchForm = ({ showButton }: { showButton?: boolean }) => (
    <FormWrapper action={`/${locale}/${isEn ? 'search' : 'busca'}/`}>
      <SearchInput
        id="search-input"
        name={isEn ? 'query' : 'consulta'} // Pair with src/pages/busca/index.tsx and src/pages/search/index.tsx
        pattern=".{3,}"
        required
        title={
          isEn
            ? 'Search input, need 3 characters minimum, click ENTER to search'
            : 'Input de busca, necessário 3 caracteres no mínimo, click ENTER para realizar a busca.'
        }
        placeholder=" "
      />
      <label htmlFor="search-input-header">{isEn ? 'Search' : 'Busca'}</label>
      {showButton && (
        <Button type="submit" aria-label={isEn ? 'Search' : 'Busca'}>
          <FaSearch style={SearchBarIcon} />
        </Button>
      )}
    </FormWrapper>
  );

  return (
    <Header>
      <Nav scroll={scroll + (open ? 76 : 0)} isTransparent={isTransparent}>
        <div>
          <Link
            href={`/${locale + '/' || ''}`}
            className="logo"
            title={isEn ? 'Go to Homepage' : 'Ir para página inicial'}
          >
            <Logo onClick={() => setOpen(false)} />
            <span hidden>App Masters</span>
          </Link>

          {!hideMenu && (
            <>
              <NavMenu>
                {menu?.items?.map((item) => (
                  <NavItem key={item.label}>
                    <Link href={item.url} passHref>
                      {item.label}
                    </Link>
                  </NavItem>
                ))}
                <NavItem>
                  <LanguageDisplay isTransparent={isTransparent} scroll={scroll}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                      <WorldIconHolder isTransparent={isTransparent} scroll={scroll} tabIndex={0}>
                        <WorldIcon title="Language" />
                      </WorldIconHolder>
                      {isEn ? 'En' : 'Pt-Br'}
                    </div>
                    <DropdownArrow isTransparent={isTransparent} scroll={scroll}>
                      <ArrowIcon />
                    </DropdownArrow>
                  </LanguageDisplay>

                  <Dropdown>
                    <DropdownItem>
                      <Link
                        href="/en"
                        locale="en"
                        className="btn-lang"
                        title={isEn ? 'Switch to english version' : 'Mudar para a versão em inglês'}
                      >
                        English
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link
                        href="/pt"
                        locale="pt"
                        className="btn-lang"
                        title={
                          isEn
                            ? 'Switch to Brazilian Portuguese version'
                            : 'Mudar para a versão em português Brasil'
                        }
                      >
                        Português
                      </Link>
                    </DropdownItem>
                  </Dropdown>
                </NavItem>
                <NavItem aria-live="assertive" className="search-item">
                  {searchOpen && <SearchForm />}
                  <SearchBarWrapper className="button-search">
                    <button
                      style={{ all: 'unset' }}
                      onClick={() => setSearchOpen(!searchOpen)}
                      title={
                        !searchOpen
                          ? isEn
                            ? 'Open search input'
                            : 'Abrir input de pesquisa'
                          : isEn
                            ? 'Close search input'
                            : 'Fechar input de pesquisa'
                      }
                    >
                      <FaSearch style={SearchBarIcon} />
                    </button>
                  </SearchBarWrapper>
                </NavItem>
              </NavMenu>
              <Hamburger
                open={open}
                onClick={() => setOpen(!open)}
                scroll={scroll + (open ? 76 : 0)}
                isTransparent={isTransparent}
              >
                <span></span>
                <span></span>
                <span></span>
              </Hamburger>
              <HamburgerMenu open={open}>
                <div style={{ marginBottom: 5 }}>
                  <SearchForm showButton />
                </div>
                {menu?.items?.map((item) => (
                  <Link href={item.url} key={item.label}>
                    <div style={{ textAlign: 'initial' }} onClick={() => setOpen(false)}>
                      {item.label}
                    </div>
                  </Link>
                ))}
                {isEn ? (
                  <Link href="/pt" locale="pt" className="btn-lang">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <BRFlag />
                      Go to Portuguese version
                    </div>
                  </Link>
                ) : (
                  <Link href="/en" locale="en" className="btn-lang">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <USFlag />
                      Ir para versão em Inglês
                    </div>
                  </Link>
                )}
              </HamburgerMenu>
            </>
          )}
        </div>
      </Nav>
    </Header>
  );
};

export default HeaderTemplate;
