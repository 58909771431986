import { Menu } from '../../../types/schema';

import FooterTemplate from './template';

export type Item = {
  label: string;
  url: string;
};

export type MenuType = {
  title?: string;
  locale: string;
  items: Item[];
};

type FooterProps = {
  data: {
    technologies: Menu[];
    footerPt: Menu[];
    footerEn: Menu[];
    services: Menu[];
  };
  locale: string;
};

const Footer: React.FC<FooterProps> = ({ data, locale }) => {
  return <FooterTemplate data={data} locale={locale} />;
};

export default Footer;
