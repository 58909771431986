import HeaderTemplate from './template';
import { Menu } from '../../../types/schema';

type HeaderProps = {
  data: {
    menus: Menu[];
  };
  locale: string;
  isTransparent: boolean;
  hideMenu?: boolean;
};

const Header: React.FC<HeaderProps> = ({ data, locale, isTransparent, hideMenu }) => {
  return (
    <HeaderTemplate hideMenu={hideMenu} locale={locale} data={data} isTransparent={isTransparent} />
  );
};

export default Header;
