import styled from 'styled-components';

export const NewsletterContainer = styled.section`
  /* background-color: ${(props) => props.theme.colors.darkBlue}; */
  background: ${(props) => props.theme.colors.darkGradient};

  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 26px;

  .content {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    min-height: 165px;
    max-width: 1200px;
    width: 100%;
  }

  .text-wrapper,
  form {
    display: flex;
    gap: 0.5rem;
    width: fit-content;
    flex-direction: column;
  }

  .text-wrapper {
    color: ${(props) => props.theme.colors.white};

    h1 {
      font-size: 1.5rem;
      font-weight: 600;
      display: inline;

      svg {
        margin-bottom: -4px;
        margin-right: 8px;
      }
    }

    p {
      font-size: 0.9rem;
    }

    h1,
    p {
      margin: 0;
      color: ${(props) => props.theme.colors.white};
    }
  }

  form {
    .input-wrapper {
      display: flex;
      gap: 0.5rem;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        background: ${(props) => props.theme.colors.blue};
        height: 44px;
        width: 180px;
        color: white;
        padding: 0px;
        cursor: pointer;
      }
    }

    .checkbox-wrapper {
      display: inline;
      max-width: 600px;
      gap: 0.5rem;
      label {
        padding-left: 24px;
        padding-right: 8px;
      }

      a {
        color: white;
        font-weight: bold;
        text-decoration: underline;
      }
    }

    label.checkbox {
      display: inline;
      position: relative;
      font-size: 0.9rem;

      &::first-letter {
        padding-left: 24px;
      }

      cursor: pointer;
      color: ${(props) => props.theme.colors.white};

      .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        bottom: 0;
        height: 0.9rem;
        width: 0.9rem;
        border: 2px solid white;
        border-radius: 4px;
        &::after {
          content: '';
          position: absolute;
          display: none;
          top: -2px;
          left: 2px;
          width: 7px;
          height: 12px;
          border: solid ${(props) => props.theme.colors.blue};
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
          background-color: white;
          &::after {
            display: block;
          }
        }
      }
    }

    .shake-animation {
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      animation-iteration-count: infinite;
    }

    .error {
      color: ${(props) => props.theme.colors.white};
      padding: 2px 4px;
      border-radius: 4px;
      width: fit-content;
      margin: 0;
      animation: horizontal-shaking 0.5s;
      svg {
        margin-bottom: -3px;
        margin-right: 4px;
      }
    }

    @keyframes horizontal-shaking {
      0% {
        transform: translateX(0);
      }
      25% {
        transform: translateX(5px);
      }
      50% {
        transform: translateX(-5px);
      }
      75% {
        transform: translateX(5px);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  @media (max-width: 980px) {
    .content {
      flex-direction: column;
      height: auto;
      gap: 2rem;
    }

    .text-wrapper,
    form {
      margin: 0 auto;
    }

    form {
      gap: 1rem;
      .checkbox-wrapper {
        label {
          max-width: 70%;
          margin: auto;
        }
      }
    }
  }
`;
