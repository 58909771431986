import styled, { css } from 'styled-components';
import media from '../../../styles/media';
import { Input } from '../../../styles/Input';

export const FormWrapper = styled.form`
  display: flex;
  margin-bottom: 5px;
  margin-right: 5px;
  position: relative;
  label {
    font-size: 1em;
    font-weight: 400;
    color: ${(props) => props.theme.colors.paragraph};
    opacity: 0.6;
    position: absolute;
    top: 4px;
    padding: 0 9px;
    color: ${(props) => props.theme.colors.darkText};
    cursor: text;
    transition:
      top 200ms ease-in,
      left 200ms ease-in,
      font-size 200ms ease-in;
  }
  input:not(:placeholder-shown) + label {
    opacity: 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin-left: 15px;
    padding: 6px;
    border: none;
  }
`;

export const SearchBarIcon = {
  fontSize: '1.3em',
  verticalAlign: 'middle',
  margin: 0,
};

export const SearchBarWrapper = styled.div`
  height: 1em;
  width: calc(1em + 8px);
  margin-right: 8;
  margin-left: 8;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Header = styled.header`
  z-index: 9;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
`;

export const Nav = styled.nav<{ isTransparent?: boolean; scroll: number }>`
  padding: 20px 26px;
  ${media('extraLarge')} {
    padding: 20px 0;
  }
  background: ${(props) => (props.isTransparent && props.scroll < 75 ? 'transparent' : '#fff')};
  color: ${(props) =>
    props.isTransparent && props.scroll < 75
      ? props.theme.colors.white
      : props.theme.colors.darkText};
  box-shadow: ${(props) =>
    props.isTransparent && props.scroll < 75 ? 'none' : '0px 1px 3px -2px rgba(0,0,0,0.6)'};
  transition: all 0.3s ease-in-out;

  //accessibility on button SearchBarWrapper
  div.button-search:focus-within {
    outline: 2px solid
      ${(props) =>
        props.isTransparent && props.scroll < 75
          ? props.theme.colors.white
          : props.theme.colors.blue};
    outline: 2px solid ${(props) => props.theme.colors.darkGrey};
    outline-offset: 2px;
    border-radius: 2px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
  }
  & > a {
    padding: 6px;
    height: inherit;
    justify-self: center;
  }

  ul {
    transition: all 0.4s ease-in-out;
    color: ${(props) =>
      props.isTransparent && props.scroll < 75
        ? props.theme.colors.white
        : props.theme.colors.grey};
    > li {
      &:hover {
        color: ${(props) =>
          props.isTransparent && props.scroll < 75
            ? props.theme.colors.white
            : props.theme.colors.darkGrey};
      }
    }
  }
  .logo svg {
    width: 250px;
    max-width: 60vw;
    height: 100%;
    ${(props) =>
      props.isTransparent &&
      props.scroll < 75 &&
      css`
        filter: grayscale(1) brightness(0) invert(1);
      `}
    a.logo:focus-visible {
      svg {
        outline: 2px solid #525252;
        outline-offset: 2px;
        border-radius: 2px;
      }
    }
  }
`;

export const NavMenu = styled.ul`
  list-style: none;
  flex-direction: row;
  align-items: center;
  display: none;
  ${media('extraLarge')} {
    display: flex;
    gap: 0.3rem;
  }
`;

export const SearchInput = styled(Input)`
  padding: 3px 6px;
  :focus-within {
    outline: 3px solid white;
    border-color: ${(props) => props.theme.colors.blue};
    border-width: 2px;
  }
`;

export const LanguageDisplay = styled.div<{ isTransparent?: boolean; scroll: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.3rem 1rem;
  cursor: pointer;
  border: 2px solid;
  border-color: ${(props) =>
    props.isTransparent && props.scroll < 75 ? props.theme.colors.white : props.theme.colors.grey};
  border-width: 2px;
  border-radius: 20px;
`;

export const WorldIconHolder = styled.div<{ isTransparent?: boolean; scroll: number }>`
  display: flex;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
  fill: ${(props) =>
    props.isTransparent && props.scroll < 75 ? props.theme.colors.white : props.theme.colors.grey};
`;

export const Dropdown = styled.ul`
  list-style: none;
  position: absolute;
  z-index: 999;
  top: 100%;
  right: 50%;
  transform: translateX(50%);
  display: none;
  background: white;
  border-radius: 15px;
  padding: 0;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`;

export const DropdownItem = styled.li`
  padding: 6px 0;
  margin: 0 1rem;
  > * {
    width: 100%;
    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
    }
  }
  .btn-lang {
    border: 0;
    background: transparent;
    cursor: pointer;

    .flag svg {
      height: 20px;
      width: 20px;
    }
  }
  button:focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkGrey};
    outline-offset: 2px;
    border-radius: 2px;
  }
`;

export const DropdownArrow = styled.div<{ isTransparent?: boolean; scroll: number }>`
  display: flex;
  height: 10px;
  width: 10px;
  align-items: center;
  justify-content: center;
  fill: ${(props) =>
    props.isTransparent && props.scroll < 75 ? props.theme.colors.white : props.theme.colors.grey};
  transition: all 0.2s ease-in-out;
  rotate: 180deg;
`;

export const NavItem = styled.li`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  a {
    padding: 8px;
  }
  a:focus-visible {
    padding: 2px 8px !important;
    outline: 2px solid ${(props) => props.theme.colors.darkGrey} !important;
    border-radius: 3px !important;
  }
  &:nth-of-type(7),
  &:nth-of-type(8) {
    position: relative;
    margin-left: 8px;
    cursor: pointer;

    &:hover,
    &:focus-within {
      & ${Dropdown} {
        display: block;
        color: ${(props) => props.theme.colors.grey};
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-bottom: 1px solid ${(props) => props.theme.colors.white};
        }
        li:hover {
          color: ${(props) => props.theme.colors.darkGrey};
        }
        li:last-child {
          border-bottom: 0;
        }
      }
      & ${DropdownArrow} {
        rotate: 270deg;
      }
    }
  }
  &:nth-of-type(7) {
    &:focus-within {
      outline: 2px solid ${(props) => props.theme.colors.darkGrey};
      outline-offset: 1px;
      border-radius: 2px;
    }
  }
`;

export const Hamburger = styled.div<{
  isTransparent?: boolean;
  scroll: number;
  open?: boolean;
}>`
  width: 30px;
  height: 30px;
  display: flex;
  ${media('extraLarge')} {
    display: none;
  }
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
  position: relative;
  span {
    position: absolute;
    display: block;
    right: 0;
    left: 0;
    padding: 2px;
    transition: all 0.3s ease-in-out;
    background-color: ${(props) =>
      props.isTransparent && props.scroll < 75
        ? props.theme.colors.white
        : props.theme.colors.darkBlue};
    border-radius: 4px;
    &:nth-child(1) {
      transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'translateY(-10px)')};
    }
    &:nth-child(2) {
      opacity: ${(props) => (props.open ? '0' : '1')};
    }
    &:nth-child(3) {
      transform: ${(props) => (props.open ? 'rotate(45deg)' : 'translateY(10px)')};
    }
  }
`;

export const HamburgerMenu = styled.div<{ open?: boolean }>`
  position: fixed;
  top: 75px;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 8px 14px;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 8;
  box-sizing: border-box;
  ${media('extraLarge')} {
    display: none;
  }
  overflow: auto;
  transition: transform 0.2s ease-in-out;
  transform: translateX(${(props) => (props.open ? '0' : 'calc(-100%)')});
  div {
    color: ${(props) => props.theme.colors.grey};
    font-weight: 400;
    padding: 10px;
    text-align: center;
    font-size: 1.3em;
  }
  .btn-lang {
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    width: 100%;

    svg {
      height: 26px;
      width: 26px;
      margin-right: 12px;
    }

    @media (max-height: 500px) {
      display: none;
    }
  }
`;

export const BannerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  > div {
    width: 100%;
    min-height: 430px;
    background-color: ${(props) => props.theme.colors.blue};
  }

  span {
    text-align: center;
    position: absolute;
  }

  h1 {
    color: #fff;
    margin: 0;
    font-weight: 600;
    font-size: 34px;
    line-height: 1.2em;
    ${media('medium', 'large')} {
      font-size: 45px;
    }
    ${media('large')} {
      font-size: 55px;
    }
  }
`;
