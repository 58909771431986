import styled from 'styled-components';
import media from '../../../styles/media';

export const FooterContainer = styled.footer`
  /* font-weight: 400;

  ${media('medium')} {
    max-height: 300px;
  } */

  background-color: ${(props) => props.theme.colors.white};
  position: relative;
`;

export const FooterBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  > * {
    height: 100%;
  }
`;

export const FooterContent = styled.div`
  padding: 100px 30px 75px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;

  a.logo {
    svg {
      width: 160px;
    }
  }

  h1 {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 30px;
    font-weight: 500;
    line-height: 1.2em;
    color: ${(props) => props.theme.colors.darkText};
  }

  p {
    margin-bottom: 10px;
    margin-top: 0;
    line-height: 1.8;
    font-size: 15px;
    color: ${(props) => props.theme.colors.darkTextPale};
    font-weight: 400;
  }

  a {
    color: ${(props) => props.theme.colors.darkTextPale};
  }
  a:focus-within {
    outline: 2px solid ${(props) => props.theme.colors.darkTextPale};
    outline-offset: 3px;
    border-radius: 3px;
  }

  ${media('medium')} {
    padding: 100px 0px 75px;
    flex-direction: row;
  }

  /* Every div inside the content */
  > * {
    &:not(:first-child) {
      margin-top: 50px;
      width: 100%;
      ${media('medium')} {
        width: initial;
        margin-top: 0;
        margin-left: 20px;
      }
    }
  }
  div.container-items {
    h1:not(:first-child) {
      padding-top: 30px;
    }
  }
  /* Makes it responsive */
  width: 100%;
  ${media('large', 'extraLarge')} {
    max-width: 950px;
  }
  ${media('extraLarge')} {
    max-width: 1120px;
  }
`;

export const LogoWrapper = styled.div`
  max-width: 160px;
  a {
    color: ${(props) => props.theme.colors.darkText};
  }
  darkTextPale a:focus-within {
    padding-top: 10px;
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  a {
    color: ${(props) => props.theme.colors.darkTextPale};
    display: block;
  }

  svg {
    font-size: 1.8em;
    margin-right: 24px;
  }
`;

export const RelevantLinks = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400 !important;
  line-height: 1.8;
`;

export const MapWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
