import styled from 'styled-components';

export const Input = styled.input`
  ::placeholder {
    font-size: 0.9em;
    color: ${(props) => props.theme.colors.lighterGrey};
  }
  font-size: 1em;
  font-weight: 400;
  transition: border-color 0.2s ease-in-out;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.lightestGrey};
  padding: 8px;
  border-radius: 6px;
  :focus,
  :active,
  :focus-within {
    border-color: ${(props) => props.theme.colors.blue};
  }
`;
